.cart-1{
    width: 70%;
    border: 2px solid purple;
    padding: 10px 10px;
    background-color: rgb(219, 70, 219);
    display: flex;
    justify-content: space-between;
    border: none;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.angles-left{
    font-size: 40px;
    color: white;
}

.basic-line {
    width: 80px;
    border: none;
    border-top: 2px solid white;
    margin: 20px 0;
  }
  .cart-1-mid{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .angles-right{
    font-size: 40px;
    color: white;
  }
  .midicons{
    font-size: 25px;
    color: white;
  }

  
