.digital-transformation-container{
    margin-top: 8%;
}


.digital-transformationpage-container-1{
    margin-top: 40px;
    margin-bottom: 70px;
    margin-left: 15%;
    margin-right: 15%;
    position: relative;
    border: 1px solid red;
    display: flex;
    flex-direction: column;
    }
      
    
    .digital-image-container {
        position: relative;
        padding: 50px;
      
      }
      .digital-transformationpage-container-1 img{
       width: 100%;
       height: auto;
       border-top-left-radius: 20px;
       border-top-right-radius: 20px;
       border-bottom-right-radius: 20px;
    
    }
   
    .downarrowbutton-digital,.phonenumberbutton-digital{
        padding: 3px 10px;
        font-size: 17px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: red;
        color: white;
        border: none;
       
    }
    .phonenumberbutton-digital {
        position: absolute;
        top: 0px;
        right: 0;
    }
    
    .phonenumberbutton-digital:hover{
        border: 1px solid red;
        background-color: white;
        color: red;
        border-top: none;
    }
    .downarrowbutton-digital:hover{
        border: 1px solid red;
        background-color: white;
        color:red;
    }
    .downarrow-digital{
        padding-left: 8px;
    }
    
    .downarrowbutton-digital{
        position: absolute;
        bottom: -30px;
        left: 0;
    }



    .digital-transformationpage-container-2{
        margin-left: 15%;
        margin-right: 15%;
        border: 1px solid red;
        display: flex;
        justify-content: center;
        padding: 30px;  
        margin-bottom: 20px;
    }
    .digital-transformationpage-container-2-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .digital-transformationpage-container-2-right h2{
        margin-bottom: 20px;
        margin-left: 60px;
        font-weight: 500;
    }
    .digital-transformationpage-container-2-right p{
        margin-left: 60px;
        font-size: 17px;
        font-weight: 500;
    }

   .digitalpara1{
    color: red;
   }
   
   .digitalpara2{
    color: darkorange;
   }

   .digitalpara3{
    color: darkgoldenrod
   }

   .digitalpara4{
    color: green
   }

   .digital-transformationpage-container-2-left img{
       width: 200px;
       height: 215px;
   }

   .smallcontainer1{
    border-radius: 20px;
   }
   .smallcontainer2{
    border-radius: 20px;
    border: 1px solid darkorange;
   }

   .smallcontainer3{
       border-radius: 20px;
       border: 1px solid darkgoldenrod;
   }

   .smallcontainer4{
    border-radius: 20px;
    border: 1px solid green;
}


@media (max-width: 1350px) {

    .digital-transformation-container{
        margin-top: 13%;
    }
    
    .digital-transformationpage-container-1{
        margin-left: 2%;
        margin-right: 2%;
    }

    .digital-transformationpage-container-2{
        margin-left: 2%;
        margin-right: 2%;
      
    }

}

@media (max-width: 900px){
    .downarrowbutton-digital,.phonenumberbutton-digital{
        font-size: 14px;
    }
}

@media (max-width: 800px){

    .digital-transformation-container{
        margin-top: 18%;
    }
    
    .digital-transformationpage-container-2{
        margin-left: 2%;
        margin-right: 2%;
       display: flex;
       flex-direction: column;
       align-items: center;

    }
    .digital-transformationpage-container-2-left{
            display: flex;
            justify-content: center;
    }
    .digital-transformationpage-container-2-left img{
        width: 120px;
        height: auto;
    }
    .digital-transformationpage-container-2-right h2{
        margin-left: 0px;
    }
    .digital-transformationpage-container-2-right p{
        margin-left: 0px;
       
    }


}
@media (max-width: 600px){

    .digital-transformation-container{
        margin-top: 26%;
    }
    
    .downarrowbutton-digital,.phonenumberbutton-digital{
        font-size: 13px;
    }
    .digital-transformationpage-container-2{
        padding: 15px;
    }
    .digital-transformationpage-container-2-left img{
        width: 100px;
        height: auto;
    }
    .digital-transformationpage-container-2-right h2{
        margin-left: 0px;
    }
    .digital-transformationpage-container-2-right p{
        margin-left: 0px;
       
    }
}

