.academy-container {
    display: flex;
    flex-direction: column;
    margin-left: 15%;
    margin-right: 15%;
    overflow: hidden; /* Ensures that academy div doesn't overflow */
    position: relative;
  }
  
  .academy {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin-top: 50px;
    margin-bottom: 90px;
    width: 100%;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    border: 1px solid #ddd; /* Optional: for visual debugging */
  }
  
  .academy-left {
    flex: 1; /* Adjusted for responsive design */
    max-width: 600px;
    height: 400px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
  }
  
  .academy-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }
  
  .academy-right {
    flex: 2; /* Adjusted for responsive design */
    max-width: 800px;
    height: 400px;
    background-color: #FFFFFF;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 15px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .academy-right h2 {
    font-weight: bold;
    color: rgb(203, 63, 203);
  }
  
  .academy-right p {
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .academy-right p::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  
  .learn-more {
    position: absolute;
    bottom: 45px;
    right: 0px;
    background-color:  rgb(203, 63, 203);
    color: white;
    padding: 10px 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .learn-more:hover {
    background-color: white;
    color:  rgb(203, 63, 203);
    border: 2px solid rgb(203, 63, 203);
  }
  
  
  
.contact-academybutton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(128, 128, 128, 0.5); /* Semi-transparent grey background */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 18px 20px;
    font-size: 22px;
    cursor: pointer;
    font-weight: 400;
    border-radius: 15px;
  }
.contact-downarrowbutton,.contact-phonenumberbutton{
    padding: 3px 10px;
    font-size: 17px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color:  rgb(25, 163, 218);
    color: white;
    border: none;
   
}
.contact-phonenumberbutton {
    position: absolute;
    top: 0px;
    right: 0;
border-top-right-radius: 20px;}

.contact-phonenumberbutton:hover{
    border: 2px solid  rgb(25, 163, 218);
    background-color: white;
    color:  rgb(25, 163, 218);
}
.contact-downarrowbutton:hover{
    border: 2px solid  rgb(25, 163, 218);
    background-color: white;
    color:  rgb(25, 163, 218);
}
.contact-downarrow{
    padding-left: 8px;
}

.contact-downarrowbutton{
    position: absolute;
    bottom: -23px;
    left: 0;
}

.contact-academypage-container-2{
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 50px;
    margin-bottom: 50px;
}

.contact-academypage-container-2 .academypara{
    font-size: 25px;
    color:  rgb(25, 163, 218);
    padding-bottom: 20px;
}
  
  
  @media (max-width: 1700px) {
    .academy {
  
      margin-left: 10%;
      margin-right: 10%;
    }
  }
  @media (max-width: 1350px) {
    .academy {
        flex-direction: column; /* Stack items vertically */
        margin-left: 2%;
        margin-right: 2%;
        justify-content: center; 
        align-items:  center;
        max-width: 100%;
    }
    .academy-left,
    .academy-right {
        width: 100%; /* Ensure full width */
        max-width: 100%;
        border-top-right-radius: 20px;
  
    }
    .academy-left img{
        width: 100%;
        max-width: 100%; /* Ensure they don’t exceed container width */
       height: 400px;
       border-top-left-radius: 20px;
       border-top-right-radius: 20px;
       border-bottom-left-radius: 0px;
    }
  
    .academy-right{
        border-top-right-radius: 0px;    
        border-bottom-left-radius: 20px;
          }
  
  }
  