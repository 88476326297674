.academypage{
    margin-top: 8%;
}




.academypage-container-1{
margin-top: 40px;
margin-bottom: 40px;
margin-left: 15%;
margin-right: 15%;

position: relative;
}
  

.image-container {
    position: relative
  }
.academypage-container-1 img{
   width: 100%;
   height: auto;
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;
   border-bottom-right-radius: 20px;

}
.academybutton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(128, 128, 128, 0.5); /* Semi-transparent grey background */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 18px 20px;
    font-size: 22px;
    cursor: pointer;
    font-weight: 400;
    border-radius: 15px;
  }
.downarrowbutton,.phonenumberbutton{
    padding: 3px 10px;
    font-size: 17px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: rgb(219, 70, 219);
    color: white;
    border: none;
   
}
.phonenumberbutton {
    position: absolute;
    top: 0px;
    right: 0;
border-top-right-radius: 20px;}

.phonenumberbutton:hover{
    border: 2px solid rgb(219, 70, 219);
    background-color: white;
    color: rgb(219, 70, 219);
}
.downarrowbutton:hover{
    border: 2px solid rgb(219, 70, 219);
    background-color: white;
    color: rgb(219, 70, 219);
}
.downarrow{
    padding-left: 8px;
}

.downarrowbutton{
    position: absolute;
    bottom: -23px;
    left: 0;
}

.academypage-container-2{
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 50px;
    margin-bottom: 50px;
}

.academypage-container-2 .academypara{
    font-size: 25px;
    color: rgb(219, 70, 219);
    padding-bottom: 20px;
}

.academypage-container-2 p{
    font-size: 18px;
}

.box-1,.box-2,.box-3,.box-4,.box-5{
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px 10px;
    border: 1px solid rgb(219, 70, 219);
    background-color:whitesmoke;
    border-radius: 10px;
    
}

.box-1:hover,.box-2:hover,.box-3:hover,.box-4:hover,.box-5:hover{
    background-color: rgba(255, 255, 255, 0.9);
}

.academypage-container-3{
    margin-left: 15%;
    margin-right: 15%;
   
    display: flex;
    gap: 15px;
   
}

.box-1 p,.box-2 p,.box-3 p,.box-4 p,.box-5 p{
      padding-top: 20px;
     padding-left: 30px;
   
}

.staricon{
   display: flex;
   justify-content: flex-end;
}

.container-3-para-1{
    margin-bottom: 30px;
}
.container-3-para{
    font-weight: 500;
    font-size: 18px;
    color: rgb(219, 70, 219);
}

.filtericonmain{
    display: flex;
    justify-content: flex-start;
    width: 70%;
}

@media (max-width: 1350px) {
    .academypage{
        margin-top: 13%;
    }
    .academypage-container-1{
        margin-left: 2%;
        margin-right: 2%;
    }
    .academypage-container-2{
        margin-left: 2%;
        margin-right: 2%;
      
    }

    .academypage-container-3{
        margin-left: 2%;
        margin-right: 2%;
        gap: 5px;
       
    }
    .box-1,.box-2,.box-3,.box-4,.box-5{
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px 10px;
      
       
        
    }

    .downarrowbutton,.phonenumberbutton{
        font-size: 16px;
    }
}

@media (max-width: 1350px) {
    .academypage-container-3{
       display: flex;
       justify-content: space-around;
       flex-wrap: wrap;
    }

}



@media (max-width: 800px) {

    .academypage{
        margin-top: 18%;
    }
    .academypage-container-2 .academypara{
        font-size: 20px;
    }
    .academypage-container-2 p{
        font-size: 16px;
    }

}
@media (max-width: 650px) {

    .academypage{
        margin-top: 25%;
    }
    .box-1,.box-2,.box-3,.box-4,.box-5{
      
  
    }



}

@media (max-width: 600px) {
.academybutton {
    padding: 10px 10px;
    font-size: 18px;
}

}