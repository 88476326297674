.cart-2{
    border: 1px solid rgb(219, 70, 219);
    width: 70%;
    outline: none;
    padding: 15px 10px;
    background-color: whitesmoke;
     
  }

  .form-container {
    display: flex;          
    justify-content: space-between; 
    gap: 20px;              
  }

  .form-group {
    display: flex;             
    flex-direction: column;     
    flex: 1;                    
  }

  .form-group label {
    margin-bottom: 5px;        
            
  }
  
  .form-group input {
    width: 100%;             
    padding: 5px;         
    border: 1px solid #ccc;    
    border-radius: 4px;        
  }


  input::placeholder {
    text-align: right;
  }

  label {
     font-size: 14px;
  }
