.digital-academy-container {
  display: flex;
  flex-direction: column;
  margin-left: 15%;
  margin-right: 15%;
  overflow: hidden; /* Ensures that academy div doesn't overflow */
  position: relative;
}

.digital-academy {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-top: 50px;
  margin-bottom: 90px;
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid #ddd; /* Optional: for visual debugging */
}

.digital-academy-left {
  flex: 1; /* Adjusted for responsive design */
  max-width: 600px;
  height: 400px;
  border-top-left-radius: 0px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
}

.digital-academy-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom-left-radius: 0px;
}

.digital-academy-right {
  flex: 2; /* Adjusted for responsive design */
  max-width: 800px;
  height: 400px;
  background-color: #FFFFFF;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 20px;
  padding: 15px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 0px;
}

.digital-academy-right h2 {
  font-weight: bold;
  color: red;
}

.digital-academy-right p {
  flex-grow: 1;
  overflow-y: auto;
}

.digital-academy-right p::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.digital-learn-more {
  position: absolute;
  bottom: 45px;
  left: 0px;
  background-color: red;
  color: white;
  padding: 10px 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}

.digital-learn-more:hover {
  background-color: white;
  color: red;
  border: 2px solid red;
}





@media (max-width: 1700px) {
  .digital-academy {

    margin-left: 10%;
    margin-right: 10%;
  }
}
@media (max-width: 1350px) {
  .digital-academy {
      flex-direction: column; /* Stack items vertically */
      margin-left: 2%;
      margin-right: 2%;
      justify-content: center; 
      align-items:  center;
      max-width: 100%;
      border-top-right-radius: 20px;
  }
  .digital-academy-left,
  .digital-academy-right {
      width: 100%; /* Ensure full width */
      max-width: 100%;
      border-top-right-radius: 20px;

  }
  .digital-academy-left img{
      width: 100%;
      max-width: 100%; /* Ensure they don’t exceed container width */
     height: 400px;
     border-top-left-radius: 20px;
     border-top-right-radius: 20px;
     border-bottom-left-radius: 0px;
  }

  .digital-academy-right{
      border-top-right-radius: 0px;    
      border-bottom-left-radius: 20px;
        }

}
