.footer{
    background-color: black;
    color: aliceblue;
   
   
}
.footercontainer-1{
    display: flex;
    justify-content: center;
    align-items: center;
  
}
.footer-1{
    margin-top: 70px;
    margin-bottom: 90px;
    margin-right: 50px;
}
.footer-2{
    margin-top: 70px;
    margin-bottom: 90px;
    margin-right: 50px;
}
.footer-3{
    margin-top: 70px;
    margin-bottom: 90px;
    margin-right: 50px;
}
.footercontainer-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #797979;
    padding-top: 50px;
    padding-bottom: 40px;
}


@media (max-width: 600px){
    .footer-1{
       
        margin-right: 30px;
    }
    .footer-2{
       
        margin-right: 30px;
    }
    .footer-3{
       
        margin-right: 30px;
    }
    .footercontainer-2{
       font-size: 15px;
    }
    
}
