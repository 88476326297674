.cart-3{
    border: 1px solid rgb(219, 70, 219);
    background-color: rgb(219, 70, 219); 
    color: white;
    width: 70%;
    outline: none;
    padding: 15px 10px;
    position: relative; /* Positioning the middle element */
    display: flex;
    justify-content: space-between;
    align-items: center;
  font-size: 18px;
    
}

.middle {
    flex: 1;
    text-align: center;
  }
  
  .end {
    margin-left: auto;
    width: 30px;
    height: 30px;
    color: white;
    
  }

.cart-4{
    border: 1px solid rgb(219, 70, 219);
    width: 70%;
    outline: none;
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    color:  rgb(219, 70, 219);
    background-color: whitesmoke
  }

  .cart-4-container{
    display: flex;
    gap: 20px;
    margin-right: 60px;
  }
