.contact{
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 60px;
    background-color: rgb(25, 163, 218);
    color: white;
 
   
  
}
.contact h2{
    padding: 40px 25px;

}

.contact p{
    padding-bottom: 30px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 18px;
}
.contact-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;

}



.formcontainer input,.formcontainer textarea{
    display: block;
    width: 100%;
    
   padding-top: 10px;
   padding-bottom: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 25px;
    padding-right: 25px;
  
    
}

.formcontainer{
   
    width: 100%;
    padding: 0 25px; /* Horizontal padding */
    margin-bottom: 50px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
   
  
}

.formcontainer textarea{
     height: 250px;
     margin-bottom: 25px;
}

.btn{
    padding: 8px 20px;
   
    margin-top: 20px;
    font-size: 18px;
    border-radius: 20px;
    background-color:white;
    color: rgb(25, 163, 218);
    border-color: white;
    outline: none;
    box-shadow: none; 
    border: none;
}
.t-c-btn{
    margin-left: 15px;
    padding: 5px 20px;
    border-radius: 20px;
    border-color: white;
    outline: none;
    box-shadow: none; 
    border: none;
    color: rgb(25, 163, 218);
    font-weight: 400;
}
.checkbox{
    display: flex;
    margin-top: 10px;

}
input::placeholder {
    text-align: left; /* Align placeholder text to the left */
  }
  
  input[type="text"]::-webkit-input-placeholder {
    text-align: left; /* Chrome/Safari/Webkit */
  }

  .checkbox-container {
    display: flex;
    
    align-items: center;
    
  }
  
  input[type="checkbox"] {
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-right: 5px;
  }
  
  label {
    font-size: 14px;
    cursor: pointer;
    margin: 0;
  }

  @media (max-width: 1350px) {

    .contact{
        margin-left: 2%;
        margin-right: 2%;
    }
    .contact h2{
        padding: 20px 10px;
    }
    .contact p{
        padding: 20px 10px;
    }
    .formcontainer{
        padding-left: 10px;
        padding-right: 10px;
    }
    .checkbox-container {
        display: flex;
        align-items: center;
      }
      
      input[type="checkbox"] {
        width: 15px;
        height: 15px;
        cursor: pointer;
        margin-right: 5px;
      }
      
      label {
        font-size: 14px;
        cursor: pointer;
      }
      
  }