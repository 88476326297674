nav{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px 0;
  font-size: 19px;
  font-weight: 500;

  z-index: 1000;    /*important....*/
  cursor: pointer;
  background-color: #F0F0F0;
  max-width: 100%;
  overflow-x: hidden;
  overflow: hidden; /* Prevent overflow from child elements */
 
}
.logo{
  width: 200px;
  height: auto;
}

.listitems{
  list-style-type: none;
  display: inline-block;
padding-right: 40px;
padding-left: 40px;

}
.listitems-1:hover{
  color: rgb(187, 107, 133)
}
.listitems-2:hover{
  color: red
}
.listitems-3:hover{
  color: skyblue
}
.listitems-4:hover{
  color: orange
}
.listitems-5:hover{
  color: skyblue
}





.hamburger-icon{
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: black;
  
}
.crossmark-icon{
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: black;
}
.nav-cart{
  position: fixed;
  top: 31px;
  right: 25px;
}

.listitems-0{
  display: none;
}
@media (max-width: 1300px) {
  .listitems{
      list-style-type: none;
      display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
    
}
.hamburger-icon {
  display: none;
  position: fixed;
  top: 24px;
  left: 40px;
}

.crossmark-icon {
  display: block;
  position: fixed;
  top: 24px;
  left: 40px;
  color: black;
  z-index: 1000; /* Ensure the cross mark icon is above everything else */
  
}
.listitems-0{
  display: none;
}

}
@media (max-width: 1150px) {
  .listitems{
    list-style-type: none;
    display: inline-block;
padding-right: 5px;
padding-left: 5px;
font-size: 16px;
  
}
.hamburger-icon {
display: none;
position: fixed;
top: 24px;
left: 40px;
}

.crossmark-icon {
display: block;
position: fixed;
top: 24px;
left: 40px;
color: black;
z-index: 1000; /* Ensure the cross mark icon is above everything else */

}
.listitems-0{
display: none;
}



}

@media (max-width: 900px) {

nav{
  
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

 .logo{
  width: 150px;
 }
.nav-links {
  display: none;
}

.nav-links-mob {
  display: flex;
  flex-direction: column;
 
  position: fixed;
  top: 0;
  left: -80%; /* Initially position off-screen to the right */
  width: 80%;
  height: 100vh;
  background-color: #F0F0F0;
  color: black;
  padding-top: 60px;
  z-index: 999; /* Ensure the mobile menu is above other elements */
  transition: left 0.8s ease-in-out;
 
 

}

.nav-links-open {
  left: 0; /* Slide in the menu */
}


.listitems{
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 30px;
 
}


.hamburger-icon {
  display: block;
  position: fixed;
  top: 24px;
  left: 40px;
}

.crossmark-icon {
  display: block;
  position: fixed;
  top: 24px;
  left: 40px;
  color: black;
  z-index: 1000; /* Ensure the cross mark icon is above everything else */
  
}
.listitems-0{
  display: block;
 
  }
  

}
@media (max-width: 800px) {
  .nav-cart{
    position: fixed;
    top: 25px;
    right: 25px;
  }

}
@media (max-width: 375px) {
  .nav-links-mob {
     height: 100vh;
      padding-top: 60px; /* Adjust padding for small screens */
  }
}
