.carousel.slide {
    background: none;
}

.carousel .carousel-image-container {
    width: 100%;
}

.carousel .carousel-image {
    width: 100%;
    height: 600px;
    object-fit: cover;
}


.carousel-container {
    margin-top: 100px; /* this classname mentoned in app.css .Adjust this value to match the height of your navbar */
    width: 100%;
   
  }

  /* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .carousel .carousel-image {
        height: 400px; /* Adjust height for tablets and smaller screens */
    }
}

@media (max-width: 480px) {
    .carousel .carousel-image {
        height: 300px; /* Adjust height for mobile devices */
    }
}